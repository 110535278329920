import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import Par from "../../components/Par";

// Download link - Download GESA Information: Colonoscopy - http://www.fbrennangastro.com.au/downloads/gesa_colonoscopy(2).pdf
// Download link - Download GESA Information: Bowel Preparation -  http://www.fbrennangastro.com.au/downloads/gesa_bowel_prep(3).pdf

const title = "Colonoscopy";
const Services = () => {
	return (
		<div>
			<SEO
				title={title}
				keywords={[
					title,
					`Gastroenterology services`,
					`Endoscopy services`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description={title}
			/>
			<H1 text={title} />
			<Par>
				A colonoscopy is a procedure used to inspect the large bowel (colon) and is usually done in a day facility or
				hospital.
			</Par>
			<Par>
				During a colonoscopy a thin flexible tube called a colonoscope is carefully fed into the large intestine. A
				small camera on the colonoscope transmits an image to a monitor, allowing close examination of the bowel and
				intestinal lining.
			</Par>
			<Par>
				A colonoscopy can detect inflamed tissue, ulcers and abnormal growths. The procedure is used to look for early
				signs of colorectal cancer and can help doctors diagnose unexplained changes in bowel habits, abdominal pain,
				bleeding from the anus and weight loss.
			</Par>
			<Par>
				It is also used for the screening and surveillance for colorectal cancer or removal of bowel polyps. Colonoscopy
				with removal of polyps is the best way to prevent bowel cancer.
			</Par>
			<Par>
				In order for your doctor to get the best possible view and make the colonoscopy easier, your large bowel needs
				to be cleaned out of all waste material.
			</Par>
		</div>
	);
};

export default Services;
